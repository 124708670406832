@import "../styles/funcs.scss";

body {
  margin: 0;
  //  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
  //    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  //    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  max-width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  font-size: calc(16 * 100vw / 360);
  width: calc(0vw + 100%);
  scroll-behavior: smooth;

  @include breakpoint(mobile_landscape) {
    font-size: calc(16 * 100vw / 667);
  }

  @include breakpoint(tablet) {
    font-size: calc(16 * 100vw / 768);
  }

  @include breakpoint(tablet_landscape) {
    font-size: calc(16 * 100vw / 960);
  }

  //@include breakpoint(desktopXL) {
  //  font-size: calc(16 * 100vw / 1920);
  // }

  // NEW LOGIC

  @include breakpoint(desktop) {
    font-size: calc(16 * 100vw / 1600);
  }

  @include breakpoint(desktopXL) {
    font-size: calc(16 * 100vw / 1920);
  }

  body {
    margin: 0;
  }
}

* {
  font-weight: 400;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul,
ol {
  list-style: none;
}

body {
  //  font-family: "Inter", sans-serif;
  color: #ffffff;
  // background: rgb(16, 26, 45);
  background: url("../assets/bg.svg"), rgb(16, 26, 45);
  background-repeat: no-repeat;
  background-position: left top;
  background-size: cover;
}

a {
  text-decoration: none;
  color: inherit;
}

#root {
  overflow: hidden;
}
