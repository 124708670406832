@import "../../../../styles/funcs.scss";

.plan-table {
  &__head-money-back {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: getRem(14);

    & img {
      width: getRem(32);
      height: getRem(32);
      margin-right: getRem(5);
    }
  }
}

.plans {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  gap: getRem(30);

  @include breakpoint(desktop) {
    flex-direction: row;
    gap: getRem(60);
  }

  & * {
    margin: 0;
  }

  &__title {
    font-size: getRem(24);
    text-align: center;
    margin: 0 0 getRem(20);

    @include breakpoint(tablet) {
      & br {
        display: none;
      }
    }

    @include breakpoint(desktop) {
      font-size: getRem(28);
    }
  }

  &__ben {
    position: relative;
    padding-top: getRem(40);
    border-radius: getRem(10);
    order: 1;

    @include breakpoint(desktop) {
      order: unset;
    }

    &-top {
      font-size: getRem(16);
      font-weight: 700;
      border-radius: getRem(10) getRem(10);
      background: var(
        --Card-Stroke,
        linear-gradient(313deg, #039 0%, #101623 50.23%, #039 100%)
      );
      backdrop-filter: blur(getRem(10));
      height: getRem(60);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      font-size: getRem(16);
      padding-top: getRem(10);

      & p {
        font-weight: 700;
      }
    }

    & .plans__item {
      border: getRem(1) solid var(--Stroke, #1481b8);
      background: var(
        --Card-Gradient,
        linear-gradient(
          131deg,
          rgba(0, 68, 204, 0.1) 0%,
          rgba(0, 34, 102, 0.1) 50.1%,
          rgba(0, 17, 51, 0.1) 100%
        )
      );

      &::before {
        display: none;
      }
    }
  }

  &__item {
    padding: getRem(20) getRem(15);
    width: getRem(340);
    height: getRem(400);
    border-radius: getRem(10);
    background: linear-gradient(
      312.52deg,
      #003399 0%,
      #101623 50.23%,
      #003399 100%
    );

    backdrop-filter: blur(getRem(10));
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: calc(100% - getRem(4));
      height: calc(100% - getRem(4));
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: getRem(10);
      z-index: -1;
    }

    &::before {
      background: rgb(16, 26, 45);
    }

    &::after {
      background: var(
        --Card-Gradient,
        linear-gradient(
          131deg,
          rgba(0, 68, 204, 0.2) 0%,
          rgba(0, 34, 102, 0.2) 50.1%,
          rgba(0, 17, 51, 0.2) 100%
        )
      );
      backdrop-filter: blur(getRem(15));
    }

    &:first-child {
      height: getRem(302);

      @include breakpoint(desktop) {
        height: getRem(370);
      }
    }

    & button {
      margin-bottom: getRem(10);
    }

    &-btm {
      width: 100%;

      & .button.secondary {
        background: linear-gradient(
          275.53deg,
          #015681,
          #1d94d0 50.23%,
          #015681
        );
      }
    }
  }

  &__period {
    font-size: getRem(28);
    font-weight: 600;
  }

  &__save {
    color: var(--Accent, #22b5ff);
    font-size: getRem(20);
    font-weight: 600;
  }

  &__old-price {
    font-size: getRem(30);
    color: #a1b2c7;
    text-decoration: line-through;
    margin-top: getRem(-10);
  }

  &__curr-price {
    font-size: getRem(60);
    font-weight: 600;
    margin: getRem(-5) 0;
  }

  &__lbl {
    font-weight: 600;
  }
}

.plans-free {
  &__card {
    width: getRem(340);
    max-width: 95%;
    padding: getRem(40);
    border-radius: getRem(20);
    border: getRem(1) solid var(--Card-Gradient, #04c);
    background: var(
      --Card-Stroke,
      linear-gradient(313deg, #039 0%, #101623 50.23%, #039 100%)
    );
    margin: getRem(40) auto;

    @include breakpoint(tablet) {
      width: getRem(340);
      margin: getRem(60) auto;
    }

    @include breakpoint(desktop) {
      margin: getRem(30) auto getRem(60);
      width: getRem(420);
    }

    & h3 {
      font-weight: 700;
      font-size: getRem(24);
      text-align: center;
      margin-bottom: getRem(20);

      @include breakpoint(desktop) {
        font-size: getRem(32);
      }

      & span {
        color: var(--Accent, #22b5ff);
      }
    }
  }

  &__title {
    font-size: getRem(32);
    text-align: center;
    margin: 0 0 getRem(5);
    font-weight: 700;

    @include breakpoint(desktop) {
      font-size: getRem(36);
    }
  }
}

.plans-home {
  scroll-margin-top: getRem(25);
  padding: getRem(60) 0;

  @include breakpoint(tablet) {
    padding: getRem(80) 0;
  }

  @include breakpoint(desktop) {
    scroll-margin-top: getRem(-25);
    padding: getRem(150) 0;
  }

  & .plans__title {
    font-size: getRem(30);

    @include breakpoint(tablet) {
      font-size: getRem(32);
    }

    @include breakpoint(desktop) {
      font-size: getRem(48);
    }
  }
  & .plans__main-lbl {
    font-size: getRem(20);

    @include breakpoint(tablet) {
      font-size: getRem(24);
    }

    @include breakpoint(desktop) {
      font-size: getRem(28);
    }
  }

  &__any-questions {
    font-size: getRem(20);
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    margin-top: getRem(40);

    @include breakpoint(tablet) {
      margin-top: getRem(50);
    }
    @include breakpoint(desktop) {
      margin-top: getRem(80);
    }

    a {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
