@import "../../../styles/funcs.scss";

.header {
  margin-bottom: getRem(30);
  background: rgba(17, 26, 45, 0.6);
  backdrop-filter: blur(getRem(10));
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  z-index: 100;

  border-bottom: 0.5px solid rgb(20, 129, 184);

  //   @include breakpoint(mobile_landscape) {
  //     padding: getRem(10) getRem(60);
  //   }

  &_container {
    padding: getRem(10) getRem(20);
    width: 100%;
    max-width: getRem(1480);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;

    @include breakpoint(tablet) {
      // width: calc(100% - getRem(360 * 2));
      // padding: getRem(10) getRem(60);
      padding: getRem(10) getRem(20);
    }

    & > button {
      display: none;

      @include breakpoint(desktop) {
        display: block;
      }
    }
  }

  .navList {
    display: none;
    padding: 0;

    @include breakpoint(desktop) {
      display: flex;
      justify-content: center;
      // margin-left: auto;
      list-style-type: none;
    }

    & li {
      margin: 0 getRem(15);
      cursor: pointer;

      &:hover,
      &:focus {
        color: rgb(34, 181, 255);
      }
    }
  }

  &__logo {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: getRem(135);
    height: getRem(36);

    // @include breakpoint(desktop) {
    //   width: getRem(170);
    //   height: getRem(45);
    // }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .get-started {
    @include breakpoint(tablet) {
      margin: 0 getRem(40) 0 auto;
    }
    @include breakpoint(desktop) {
      margin: 0;
    }
  }
}
