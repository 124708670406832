@import "../../../styles/funcs.scss";

.hero {
  &_section {
    margin: getRem(100) auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: getRem(20);
    overflow: hidden;

    @include breakpoint(tablet) {
      max-width: getRem(700);
      flex-direction: row;
      gap: 0;
      margin: getRem(100) auto getRem(50);
    }
    @include breakpoint(desktop) {
      max-width: getRem(1128);
      margin: getRem(170) auto getRem(150);
    }
  }

  &_textContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: getRem(360);
    gap: getRem(30);
    margin: 0 auto;

    @include breakpoint(tablet) {
      gap: getRem(40);
      margin: 0;
    }
    @include breakpoint(desktop) {
      max-width: getRem(600);
      gap: getRem(48);
    }
  }

  &_headingBox {
    position: relative;
    max-width: getRem(320);
    margin: 0 auto;

    @include breakpoint(tablet) {
      margin: 0;
    }
    @include breakpoint(desktop) {
    }

    h1 {
      font-size: getRem(30);
      // width: getRem(320);
      font-weight: 500;
      line-height: 1.3;
      letter-spacing: 0.02em;
      text-align: right;
      margin: 0 auto;

      @include breakpoint(tablet) {
        width: getRem(331);
        font-size: getRem(32);
      }
      @include breakpoint(desktop) {
        width: getRem(516);
        font-size: getRem(50);
      }

      span {
        margin-right: getRem(12);

        @include breakpoint(tablet) {
          margin-right: getRem(29);
        }
        @include breakpoint(desktop) {
          margin-right: getRem(38);
        }
      }
    }
    .v-slider-frame {
      position: absolute;
      bottom: getRem(-3);
      left: getRem(9);
      height: getRem(42);
      width: getRem(100);
      overflow: hidden;
      text-align: center;
      z-index: 1;

      @include breakpoint(tablet) {
        bottom: getRem(-1);
        left: 0;
      }
      @include breakpoint(desktop) {
        bottom: getRem(5);
        height: getRem(60);
        width: getRem(154);
      }
    }
    ul.v-slides {
      list-style-type: none;
      // transform: translateY(50px);
      padding: 0;
      margin: 0;
    }
    .v-slide {
      font-size: getRem(30);
      font-weight: 500;
      line-height: 1.3;
      letter-spacing: 0.02em;
      color: #4bb3fd;
      text-align: left;

      @include breakpoint(tablet) {
        font-size: getRem(32);
      }
      @include breakpoint(desktop) {
        font-size: getRem(50);
      }
    }
  }

  &_price {
    font-size: getRem(24);
    font-weight: 400;
    line-height: 1.18;
    opacity: 0;
    text-align: center;

    @include breakpoint(tablet) {
      text-align: left;
    }
    @include breakpoint(desktop) {
      font-size: getRem(32);
    }

    span {
      font-weight: 700;
      color: rgb(34, 181, 255);
    }
  }

  &_btnBox {
    display: flex;
    gap: getRem(20);
    opacity: 0;
    flex-direction: column;
    align-items: center;

    @include breakpoint(tablet) {
      gap: getRem(30);
      flex-direction: row;
    }
    @include breakpoint(desktop) {
      gap: getRem(40);
      flex-direction: row;
    }

    &_btn {
      width: getRem(260);

      @include breakpoint(tablet) {
        width: getRem(180);
      }
      @include breakpoint(desktop) {
      }
    }
  }

  &_animationBlock {
    position: relative;
    width: getRem(320);
    height: getRem(320);
    border-radius: 50%;
    background: linear-gradient(180deg, #0d1626 0%, #152036 100%);
    clip-path: inset(0 0 0 0 round 0 0 50% 50%);
    opacity: 0;
    top: 40%;
    // left: 40%;

    @include breakpoint(tablet) {
      left: 40%;
      width: getRem(345);
      height: getRem(345);
    }
    @include breakpoint(desktop) {
      width: getRem(460);
      height: getRem(460);
    }
  }

  &_mobile1 {
    position: absolute;
    top: getRem(100);
    left: getRem(40);
    width: getRem(160);
    height: getRem(320);
    transform: rotate(-12deg);

    @include breakpoint(tablet) {
      width: getRem(172);
      height: getRem(345);
    }
    @include breakpoint(desktop) {
      top: getRem(150);
      width: getRem(230);
      height: getRem(460);
    }
  }
  &_mobile2 {
    position: absolute;
    top: getRem(100);
    right: getRem(40);
    width: getRem(160);
    height: getRem(320);
    transform: rotate(12deg);

    @include breakpoint(tablet) {
      width: getRem(172);
      height: getRem(345);
    }
    @include breakpoint(desktop) {
      top: getRem(150);
      width: getRem(230);
      height: getRem(460);
    }
  }

  &_ip {
    &Card {
      border-radius: getRem(10);
      background: var(
        --Card-Gradient,
        linear-gradient(
          131deg,
          rgba(0, 68, 204, 0.2) 0%,
          rgba(0, 34, 102, 0.2) 50.1%,
          rgba(0, 17, 51, 0.2) 100%
        )
      );
      padding: getRem(10);
      opacity: 0;
      margin: 0 auto;

      @include breakpoint(tablet) {
        margin: 0;
      }

      @include breakpoint(desktop) {
        padding: getRem(20);
      }

      & p {
        color: var(--Light-Gray, #b3b3b3);
        font-size: getRem(18);

        @include breakpoint(desktop) {
          font-size: getRem(20);
        }

        & span {
          font-size: getRem(20);
          color: var(--White, #fff);
          font-weight: 500;

          @include breakpoint(desktop) {
            font-size: getRem(24);
          }
        }
      }
    }

    &Status {
      color: var(--Red, #da1414) !important;
      transition: 0.7s all;
      font-weight: 500;

      &.protected {
        color: #73ff49 !important;
      }

      &.activating {
        color: var(--Yellow, #e9ee00) !important;
      }
    }
  }

  &_loader {
    width: getRem(28);
    height: getRem(28);
    margin-top: getRem(-50);
    margin-left: getRem(10);
  }
}

@keyframes play {
  0% {
    outline-color: rgba(255, 255, 255, 0.5);
    outline-offset: 0px;
  }
  20% {
    outline-color: rgba(255, 255, 255, 0);
    outline-offset: 10px;
  }
  100% {
    outline-color: rgba(255, 255, 255, 0);
    outline-offset: 10px;
  }
}
