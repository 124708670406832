@import "../../../styles/funcs.scss";

.dropdown .dropdown {
    &__control {
        height: getRem(56);
        border-radius: var(--Input-Radius, 10px);
        border: getRem(1) solid var(--Stroke, #1481B8);
        background: var(--BG, #101A2D);
        transition: all 0.2s linear;
        cursor: pointer;

        &:hover {
            border-color: var(--Stroke, #1481B8);
        }
    }

    &__input-container {
        color: #FFFFFF;
    }

    &__input::placeholder {
        color: #fff;
    }

    &__control--is-focused {
        box-shadow: 0 0 5px #1481B8;
    }

    &__menu {
        border: getRem(1) solid var(--Stroke, #1481B8);
        border-radius: getRem(10);
        overflow: hidden;
        background: var(--BG-2, #05090F);
        z-index: 50;
    }


    &__custom-option {
        display: flex;
        align-items: center;
        padding: getRem(10) getRem(16);
        cursor: pointer;
        position: relative;

        &:hover {
            background: rgba(20, 129, 184, 0.2);
        }

        &:not(:last-child) {
            border-bottom: getRem(1) solid var(--Stroke, rgb(20, 129, 184));
        }

        &-img {
            width: getRem(32);
            height: getRem(32);
        }

        &-name {
            margin-left: getRem(10);
            font-size: getRem(18);
            font-family: "Inter";
            font-weight: 500;
        }

        &.dropdown__custom-option--is-selected::after {
            content: "✔";
            color: #fff;
            position: absolute;
            right: getRem(10);
            top: 50%;
            transform: translate(0, -50%);
        }
    }

    &__custom-single-value {
        display: flex;
        padding: getRem(10) getRem(16);
        align-items: center;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        z-index: 1;
    }

    &__placeholder {
        color: #fff;
    }

    &__single-value {
        color: #333;
    }
}
