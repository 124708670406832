@import "../../../styles/funcs.scss";

.success-pay-popup {
  width: fit-content;

  @include breakpoint(tablet) {
    padding: getRem(0) !important;
  }

  h2 {
    font-size: getRem(24);
    margin: getRem(10) 0;
    font-weight: 600;

    @include breakpoint(desktop) {
      font-size: getRem(28);
    }
  }

  &__lbl {
    line-height: 1.5;
    font-size: getRem(16);
    margin-bottom: getRem(25);
  }

  &__desc {
    font-size: getRem(16);
    max-width: getRem(500);
    margin: 0 auto getRem(30);

    @include breakpoint(tablet) {
      font-size: getRem(18);
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: getRem(20) getRem(20) getRem(5);
    position: relative;

    & .profile-download__content {
      flex-direction: column;
      align-items: center;

      @include breakpoint(tablet) {
        flex-direction: row;
        align-items: flex-start;
      }
    }

    & .profile-download__box {
      margin: 0;
      padding: getRem(20);

      @include breakpoint(tablet) {
        padding: getRem(20) 0;
      }

      @include breakpoint(tablet_landscape) {
        padding: getRem(20);
      }

      @include breakpoint(desktop) {
        padding: getRem(30) getRem(40);
      }
    }

    & .profile-download__desc {
      font-size: getRem(14);

      @include breakpoint(desktop) {
        font-size: getRem(20);
      }
    }

    & .profile-download__content-item > p,
    & .profile-download__content-item > img {
      display: none;

      @include breakpoint(desktop) {
        display: block;
      }
    }

    @media (max-width: 767px) {
      & .profile-download__box {
        gap: getRem(10);
        padding: 0;
      }

      & .profile-download__item > p {
        display: none;

        @include breakpoint(tablet) {
          display: block;
        }
      }
    }
  }
}
