@import "../../../styles/funcs.scss";

.benefits {
  &_section {
    max-width: getRem(1120);
    margin: getRem(60) auto 0;

    @include breakpoint(tablet) {
      max-width: getRem(740);
      margin: getRem(80) auto 0;
    }
    @include breakpoint(desktop) {
      max-width: getRem(1120);
      margin: getRem(150) auto 0;
    }
  }

  &_list {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    padding: getRem(40) getRem(25);
    border-radius: getRem(20);
    overflow: hidden;
    border: getRem(1) solid var(--Stroke, #1481b8);
    background: var(--White, #fff);
    z-index: 1;
    width: getRem(320);
    margin: 0 auto;

    @include breakpoint(tablet) {
      padding: getRem(30) getRem(30) getRem(27);
      width: getRem(740);
    }
    @include breakpoint(desktop) {
      padding: getRem(40);
      width: getRem(1120);
    }
  }

  &_listItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: getRem(120);
    height: getRem(106);
    opacity: 0;
    transform: translate(0, 150%);

    &:nth-child(1),
    &:nth-child(2) {
      margin-bottom: getRem(40);
    }

    @include breakpoint(tablet) {
      width: getRem(140);
      &:nth-child(1),
      &:nth-child(2) {
        margin-bottom: 0;
      }
    }
    @include breakpoint(desktop) {
      width: getRem(200);
      height: getRem(157);
    }

    img {
      width: getRem(36);
      height: getRem(36);

      @include breakpoint(desktop) {
        width: getRem(48);
        height: getRem(48);
      }
    }

    .digit {
      font-size: getRem(32);
      font-weight: 400;
      line-height: 1.2;
      text-align: center;
      margin-top: getRem(10);
      color: var(--Almost-Black, #101010);

      @include breakpoint(tablet) {
        font-size: getRem(36);
        // margin-top: getRem(20);
      }
      @include breakpoint(desktop) {
        font-size: getRem(50);
        margin-top: getRem(20);
      }
    }
    .text {
      font-size: getRem(14);
      font-weight: 300;
      line-height: 1.2;
      text-align: center;
      margin-top: getRem(4);
      white-space: nowrap;
      color: var(--Almost-Black, #101010);

      @include breakpoint(tablet) {
        font-size: getRem(16);
      }
      @include breakpoint(desktop) {
        font-size: getRem(20);
      }
    }
  }
}

.advantages {
  background: var(--BG-Light-Blue, rgba(214, 241, 255, 0.9));
  padding: getRem(60) 0;
  position: relative;
  z-index: 0;
  overflow: hidden;

  @include breakpoint(tablet) {
    padding: getRem(80) 0;
  }

  @include breakpoint(desktop) {
    padding: getRem(150) 0;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: 1000;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(135deg);
    width: 80%;
    padding-bottom: 80%;
    border-radius: getRem(157.261);
    opacity: 0.2;
    background: linear-gradient(
      225deg,
      #fff 1.68%,
      rgba(255, 255, 255, 0) 78.67%
    );
  }

  &-servers {
    &__title {
      color: var(--Stroke, #1481b8);
      font-size: getRem(14);
      font-weight: 700;
      text-align: center;

      @include breakpoint(tablet) {
        font-size: getRem(16);
      }

      @include breakpoint(desktop) {
        font-size: getRem(20);
      }
    }

    &__subtitle {
      font-size: getRem(20);
      font-weight: 600;
      color: var(--Almost-Black, #101010);
      text-align: center;
      margin-bottom: getRem(20);

      @include breakpoint(tablet) {
        font-size: getRem(32);
        margin-bottom: getRem(50);
      }

      @include breakpoint(desktop) {
        font-size: getRem(44);
      }
    }

    &__box {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: getRem(20);
      max-width: getRem(800);
      margin: 0 auto;
      width: getRem(350);

      @include breakpoint(tablet) {
        gap: getRem(20);
        width: getRem(700);
      }

      @include breakpoint(desktop) {
        width: getRem(1460);
        max-width: getRem(1460);
        gap: getRem(40);
      }

      & img {
        width: getRem(50);
        height: getRem(32);

        @include breakpoint(tablet) {
          width: getRem(70);
          height: getRem(45);
        }

        @include breakpoint(desktop) {
          width: getRem(110);
          height: getRem(70);
        }
      }
    }
  }

  &-speed {
    margin-top: getRem(60);

    @include breakpoint(tablet) {
      margin-top: getRem(80);
    }

    @include breakpoint(desktop) {
      margin-top: getRem(150);
    }
  }
}

.advantages-speed__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: getRem(20);

  @include breakpoint(tablet) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: getRem(40);
    padding: 0;
  }

  @include breakpoint(desktop) {
    gap: getRem(60);
  }
}

.advantages-circle-1,
.advantages-circle-2 {
  transform-origin: center;
  transition: 0.35s all;
  transform: rotate(0deg);
  display: none;
}

.advantages-circle-1_,
.advantages-circle-2_ {
  width: getRem(120);
  height: getRem(120);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.5;
  border-radius: 50%;
  border: getRem(7.5) solid var(--Stroke, #1481b8);
  clip-path: polygon(0% 75%, 50% 50%, 100% 50%, 100% 100%, 0% 100%);
}

.advantages-circle-2_ {
  transform: translate(-50%, -50%) rotate(-90deg);
}

.advantages-speed__title,
.advantages-speed__subtitle {
  font-size: getRem(24);
  font-weight: 700;
  color: var(--Stroke, #1481b8);
  letter-spacing: getRem(1);
  position: relative;

  @include breakpoint(tablet) {
    font-size: getRem(32);
  }

  @include breakpoint(desktop) {
    font-size: getRem(50);
  }
}

.advantages-speed__title {
  order: 1;
  font-size: getRem(18);
  margin-bottom: getRem(100);
  margin-left: getRem(15);

  @include breakpoint(tablet) {
    text-align: right;
    order: unset;
    font-size: getRem(32);
    margin-bottom: 0;
    width: getRem(258);
    margin-left: 0;
  }

  @include breakpoint(desktop) {
    width: auto;
  }

  &::after {
    content: "";
    width: getRem(150);
    background: url("../../../assets/speed-anim/border-frame-m.svg");
    background-size: 100% 100%;
    position: absolute;
    left: 0;
    right: auto;
    bottom: getRem(-20);
    height: getRem(28);

    @include breakpoint(tablet) {
      width: getRem(300);
      background: url("../../../assets/speed-anim/border-frame-t.svg");
      bottom: getRem(-10);
    }

    @include breakpoint(desktop) {
      background-image: url("../../../assets/border-frame.svg");
    }
  }
}

.advantages-speed__subtitle {
  font-weight: 500;
  top: getRem(-50);
  color: var(--Almost-Black, #101010);
  transition: 0.35s all;
  order: 2;
  width: getRem(150);
  margin-left: getRem(25);

  @include breakpoint(tablet) {
    order: unset;
    width: getRem(200);
    margin-left: 0;
  }

  @include breakpoint(desktop) {
    width: getRem(280);
  }

  &.active {
    color: var(--Stroke, #1481b8);
    text-shadow: 0 0 getRem(10) rgba(34, 181, 255, 0.6);
    font-size: getRem(36);
    font-weight: 700;
    font-style: italic;
    background: var(
      --Button-Gradient-2,
      linear-gradient(276deg, #004161 0%, #007ebd 50.23%, #004061 100%)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @include breakpoint(tablet) {
      font-size: getRem(50);
    }

    @include breakpoint(desktop) {
      font-size: getRem(70);
    }
  }

  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: auto;
    right: 0;
    height: getRem(2);
    width: 120%;
    background: #1481b8;
  }
}

.speed-box {
  width: getRem(157);
  height: getRem(157);
  position: relative;
  border-radius: 50%;
  background: linear-gradient(
    131deg,
    rgba(0, 68, 204, 0.05) 0%,
    rgba(0, 34, 102, 0.05) 50.1%,
    rgba(0, 17, 51, 0.05) 100%
  );

  grid-row: 1/3;

  @include breakpoint(tablet) {
    width: getRem(210);
    height: getRem(210);
  }

  & span {
    //    background: blue;
    position: absolute;
    top: 50%;
    left: 50%;
    transition: 0.7s all;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
      transition: 0.7s all;
      opacity: 0;
    }

    &.active {
      &:nth-child(2n + 1) {
        filter: drop-shadow(0px 0px 10px rgba(34, 181, 255, 0.6));
      }

      &::after {
        opacity: 1;
      }
    }

    &:nth-child(2n + 1) {
      width: 5.71%;
      height: 11.9%;
      background: url("../../../assets/speed-anim/main-rectangle.svg");
      background-size: 100% 100%;

      &::after {
        background: url("../../../assets/speed-anim/main-rectangle-filled.svg");
        background-position: center;
        background-size: 100% 100%;
        filter: drop-shadow(0px 0px 10px rgba(34, 181, 255, 0.6));
      }
    }

    &:nth-child(2n) {
      width: 1.42%;
      height: 7.14%;
      background: url("../../../assets/speed-anim/small-rectangle.svg");
      background-size: 100% 100%;
    }
  }

  & img {
    width: 30.47%;
    height: 30.47%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
