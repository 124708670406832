@import "../../../styles/funcs.scss";

.withdraw-popup {
  &__form {
    & .base-modal__title {
      margin-bottom: getRem(40);
    }
  }

  &__currency {
    display: flex;
    margin-bottom: getRem(20);

    &-item {
      display: flex;
      align-items: center;
      cursor: pointer;

      & > span {
        margin-left: getRem(10);
      }

      & + .withdraw-popup__currency-item {
        margin-left: getRem(50);
      }
    }
  }

  &__lbl {
    text-align: left;
    font-size: getRem(14);
    margin-bottom: getRem(10);

    @include breakpoint(tablet) {
      font-size: getRem(16);
    }
  }

  &__btns {
    display: flex;
    justify-content: space-between;
    gap: getRem(20);
  }

  &__confirm {
    padding: getRem(20) 0;
    text-align: center;

    & > div + div {
      margin-top: getRem(20);
    }

    &-amount {
      font-size: getRem(20);
      font-weight: 600;
      margin-top: getRem(5);
      color: #22b5ff;

      @include breakpoint(tablet) {
        font-size: getRem(24);
      }
    }

    &-address {
      font-size: getRem(18);
      line-height: 1.1;
      margin-top: getRem(5);
      color: #22b5ff;
      word-break: break-all;
    }
  }
}
