@import "../../styles/funcs.scss";

.privacy-policy {
  text-align: left;
  color: white;
  padding: getRem(80) 3vw;
  font-size: getRem(14);
  line-height: getRem(22);

  @include breakpoint(desktop) {
    padding: getRem(100) 20vw;
    font-size: getRem(20);
    line-height: getRem(28);
  }

  & p {
    margin: 0;
  }

  & h1 {
    text-align: center;
    line-height: normal;
    font-size: getRem(24);
    margin: getRem(15) 0 getRem(30);

    @include breakpoint(mobile_landscape) {
      font-size: getRem(32);
    }
  }

  & h3 {
    margin: 0 0 getRem(10);
  }

  & h4 {
    margin: 0 0 getRem(10);
  }

  & ul {
    margin-top: 0;
    list-style-type: disc;
    list-style-position: inside;
    padding-left: getRem(20);

    & li {
      margin: getRem(5) 0;
    }
  }

  & a {
    text-decoration: underline;
    text-underline-offset: getRem(3);
  }
}
