@import "../../../styles/funcs.scss";

.loader {
  &__spinner {
    width: getRem(50);

    & svg {
      width: auto;
      height: auto;
    }
  }
}

.loader-v2 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  z-index: 1000;
  filter: blur(getRem(5));

  &__spinner {
    width: getRem(50);

    & svg {
      width: auto;
      height: auto;
    }
  }
}
