@font-face {
    font-family: "Inter";
    src: url("/public/fonts/Inter_18pt-Regular.ttf") format('truetype');
    font-weight: 400;
    font-display: fallback;
}
  
@font-face {
    font-family: "Inter";
    src: url("/public/fonts/Inter-VariableFont_opsz\,wght.ttf") format('truetype');
    font-weight: 500;
    font-display: fallback;
}
  
@font-face {
    font-family: "Inter";
    src: url("/public/fonts/Inter-Bold.ttf") format('truetype');
    font-weight: 700;
    font-display: fallback;
}

body {
    font-family: 'Inter', sans-serif;
}