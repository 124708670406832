@import "../../../styles/funcs.scss";

.mobile-menu {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 150%;
  top: 0;
  transform: translate(-50%, 0);
  transition: 0.7s all;
  z-index: 1000;
  background: rgba(16, 26, 45, 1);
  backdrop-filter: blur(getRem(10));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: getRem(50) 0;

  &.active {
    left: 50%;
  }

  &_logoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      font-family: TeX Gyre Heros, sans-serif;
      font-size: getRem(36);
      font-weight: 400;
      line-height: 1.43;
      text-align: center;
      background: -webkit-linear-gradient(180deg, #80ff00 0%, #65b516 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &__logo {
    width: getRem(135);
    height: auto;
    // margin-top: getRem(25);
  }

  &__btns {
    display: flex;
    flex-direction: column;
    align-items: center;

    & button {
      margin: getRem(5) 0;
      width: getRem(280);
    }
  }

  & ul {
    list-style: none;
    padding: 0;

    & li {
      text-align: center;
      margin: getRem(30) 0;
      font-size: getRem(16);
      font-weight: 400;
      line-height: 1.21;

      @include breakpoint(tablet) {
        font-size: getRem(24);
      }
    }
  }
}
