@import "../../../styles/funcs.scss";

.logout-popup {
  &__btn-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: getRem(10);

    & > button {
      width: 47% !important;
    }
  }
}
