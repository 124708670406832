@import "../../../styles/funcs.scss";

.footer {
  background: rgba(7, 11, 19, 1);
  width: 100%;

  &_container {
    width: 100%;
    // max-width: getRem(1320);
    margin: 0 auto;
    padding: getRem(30) 0 getRem(10) 0;

    @include breakpoint(tablet) {
      padding: getRem(30) getRem(20) getRem(10);
    }
    @include breakpoint(desktop) {
      max-width: getRem(1320);
    }
  }

  &_topBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap: getRem(36);

    @include breakpoint(tablet) {
      flex-direction: row;
      gap: getRem(20);
    }
    @include breakpoint(desktop) {
      gap: getRem(36);
    }
  }

  &_logo {
    width: getRem(135);
    height: getRem(36);
    order: 1;

    @include breakpoint(tablet) {
      order: 0;
    }

    @include breakpoint(desktop) {
      width: getRem(169);
      height: getRem(45);
    }
  }

  &_getStarted {
    order: 2;

    @include breakpoint(tablet) {
      order: 0;
    }
  }

  &_nav {
    display: flex;
    flex-direction: column;
    gap: getRem(24);
    order: 3;

    @include breakpoint(tablet) {
      flex-direction: row;
      gap: getRem(12);
      order: 0;
    }
    @include breakpoint(desktop) {
      gap: getRem(50);
    }
  }

  &_navLink {
    font-size: getRem(16);
    font-weight: 400;
    line-height: 1.21;
    text-align: center;

    @include breakpoint(tablet) {
      font-size: getRem(14);
    }

    @include breakpoint(desktop) {
      font-size: getRem(16);
    }

    &:hover,
    &:focus {
      color: rgba(34, 181, 255, 1);
    }
  }

  &_bottomBox {
    margin-top: getRem(50);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: getRem(24);

    @include breakpoint(tablet) {
      flex-direction: row;
      margin-top: getRem(50);
    }

    p,
    a {
      font-family: SF Pro Display, sans-serif;
      font-size: getRem(14);
      font-weight: 400;
      line-height: 1.19;

      @include breakpoint(desktop) {
        font-size: getRem(16);
      }
    }
  }

  &_termsBox {
    display: flex;
    gap: getRem(10);

    @include breakpoint(desktop) {
      gap: getRem(31);
    }
  }

  &-support {
    font-size: getRem(12);
    text-align: center;
    margin-top: getRem(40);

    @include breakpoint(tablet) {
      margin-top: getRem(40);
      font-size: getRem(14);
    }

    &__phone {
      font-size: getRem(18);

      @include breakpoint(tablet) {
        font-size: getRem(20);
      }
    }
  }
}
