@import "../../../styles/funcs.scss";

.success-pay-na {
  h2 {
    font-size: getRem(24);
    font-weight: 600;

    @include breakpoint(tablet) {
      font-size: getRem(28);
    }
  }

  &__desc {
    font-size: getRem(18);
    font-weight: 600;
    padding: 0 5%;
    margin: getRem(10) 0 getRem(20);
  }

  &__title-lbl {
    font-size: getRem(18);
    margin: getRem(5) 0 getRem(30);
  }
}
