$mobile: 360px;
$mobile_landscape: 666px;
$tablet: 768px;
$tablet_landscape: 960px;
$desktop: 1024px;
$desktopL: 1440px;
$desktopXL: 1780px;

@function getRem($size) {
  $remSize: calc($size / 16);
  @return #{$remSize}rem;
}

@mixin breakpoint($point) {
  @if $point == mobile {
    @content;
  } @else if $point == mobile_landscape {
    @media screen and (min-width: 667px), screen and (min-aspect-ratio: 13/9) {
      @content;
    }
  } @else if $point == tablet {
    @media screen and (min-width: 768px) {
      @content;
    }
  } @else if $point == tablet_landscape {
    @media screen and (min-width: 960px), screen and (min-aspect-ratio: 13/9) {
      @content;
    }
  } @else if $point == desktop {
    @media screen and (min-width: 1025px) {
      @content;
    }
  } @else if $point == desktopL {
    @media screen and (min-width: 1440px) {
      @content;
    }
  } @else if $point == desktopXL {
    @media screen and (min-width: 1780px) {
      @content;
    }
  }
}
