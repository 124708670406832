@import "../../../styles/funcs.scss";

.base-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(
    137.06deg,
    rgba(5, 26, 101, 0.6) 0%,
    rgba(9, 2, 85, 0.6) 100%
  );
  text-align: center;
  box-sizing: border-box;
  border-radius: getRem(10);
  border: getRem(1.5) solid var(--Stroke, #1481b8);
  background: var(--BG, #101a2d);
  backdrop-filter: blur(getRem(10));

  &__title {
    font-size: getRem(24);
    font-weight: 600;
    margin-bottom: getRem(10);

    @include breakpoint(tablet) {
      font-size: getRem(28);
    }
  }

  &__close {
    position: absolute;
    right: getRem(12.69);
    top: getRem(12.69);
    cursor: pointer;
    z-index: 1000;

    img {
      width: getRem(24);
      height: getRem(24);
    }
  }

  &__container {
    width: getRem(520);
    max-width: 90vw;
    max-height: 90vh;
    overflow-y: auto;
    padding: getRem(30) getRem(20);

    @include breakpoint(tablet) {
      padding: getRem(60) getRem(80);
      min-width: getRem(520);
    }
  }
}
