@import "../../../styles/funcs.scss";

.paywall {
  &-plans {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: getRem(55);

    @include breakpoint(desktop) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }

    &__title {
      font-size: getRem(20);
      text-align: center;
      margin: 0;
      font-weight: 600;

      @include breakpoint(tablet) {
        margin: 0 0 getRem(10);
      }

      @include breakpoint(desktop) {
        font-size: getRem(28);
        margin: 0 0 getRem(10);
        width: 100%;
      }
    }
  }

  & .plan-table__head-money-back {
    display: none;
  }

  &__ben {
    display: contents;

    @include breakpoint(desktop) {
      display: block;
      margin-left: getRem(60);
      position: relative;
      padding-top: getRem(40);
      border-radius: getRem(10);

      & .paywall-plan {
        border: getRem(1) solid var(--Stroke, #1481b8);
        background: var(
          --Card-Gradient,
          linear-gradient(
            131deg,
            rgba(0, 68, 204, 0.1) 0%,
            rgba(0, 34, 102, 0.1) 50.1%,
            rgba(0, 17, 51, 0.1) 100%
          )
        );

        &::before {
          display: none;
        }
      }
    }

    &-top {
      display: none;

      @include breakpoint(desktop) {
        display: block;
        font-size: getRem(16);
        font-weight: 700;
        border-radius: getRem(10) getRem(10);
        background: var(
          --Card-Stroke,
          linear-gradient(313deg, #039 0%, #101623 50.23%, #039 100%)
        );
        backdrop-filter: blur(getRem(10));
        height: getRem(60);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        font-size: getRem(16);
        padding-top: getRem(15);
        text-align: center;
      }

      & p {
        font-weight: 700;
      }
    }

    & + .paywall-plan {
      @include breakpoint(desktop) {
        margin-left: getRem(60);
      }
    }
  }

  &-plan {
    width: getRem(320);
    position: relative;
    padding: getRem(10) getRem(15) getRem(10);
    border-radius: getRem(10);
    border: getRem(1) solid var(--Stroke, #1481b8);
    background: var(--BG, #101a2d);
    backdrop-filter: blur(getRem(10));
    margin: getRem(9) 0;

    @include breakpoint(desktop) {
      border: none;
      padding: getRem(20) getRem(15);
      width: getRem(340);
      height: getRem(400);
      background: linear-gradient(
        312.52deg,
        #003399 0%,
        #101623 50.23%,
        #003399 100%
      );
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      &::before,
      &::after {
        content: "";
        position: absolute;
        width: calc(100% - getRem(4));
        height: calc(100% - getRem(4));
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: getRem(10);
        z-index: -1;
      }

      &::before {
        background: rgb(16, 26, 45);
      }

      &::after {
        background: var(
          --Card-Gradient,
          linear-gradient(
            131deg,
            rgba(0, 68, 204, 0.2) 0%,
            rgba(0, 34, 102, 0.2) 50.1%,
            rgba(0, 17, 51, 0.2) 100%
          )
        );
        backdrop-filter: blur(getRem(15));
      }

      &:nth-child(3) {
        order: 1;
      }

      & + .paywall-plan {
        margin-left: getRem(60);
      }

      & .plan-table__head-money-back {
        display: flex;
        margin-top: getRem(5);
      }
    }

    &__period {
      font-size: getRem(20);
      font-weight: 700;
      text-align: center;
      margin: 0 0 getRem(12);

      @include breakpoint(desktop) {
        font-size: getRem(28);
        margin: 0 0 getRem(3);
      }
    }

    &__save {
      display: none;

      @include breakpoint(desktop) {
        display: block;
        font-size: getRem(20);
        font-weight: 600;
        color: var(--Accent, #22b5ff);
        margin-bottom: auto;
      }
    }

    &__price-box {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      justify-content: center;
      gap: 0 getRem(15);

      @include breakpoint(desktop) {
        margin: getRem(-15) 0 auto;
      }
    }

    &__lbl {
      width: 100%;
      text-align: center;
      font-size: getRem(12);
      margin: 0 0 getRem(10);
      font-weight: 600;

      @include breakpoint(desktop) {
        font-size: getRem(16);
        margin-bottom: auto;
      }
    }

    &__old-price {
      margin: 0;
      color: var(--Light-Gray, #b3b3b3);
      font-size: getRem(24);
      text-decoration: line-through;
      margin: getRem(15) 0 getRem(3);

      @include breakpoint(desktop) {
        font-size: getRem(30);
        margin: 0;
      }
    }

    &__curr-price {
      margin: 0;
      color: var(--Accent, #22b5ff);
      font-size: getRem(36);
      font-weight: 600;

      @include breakpoint(desktop) {
        color: var(--White, #fff);
        font-size: getRem(60);
        width: 100%;
        text-align: center;
        margin-top: getRem(-5);
      }
    }

    & button {
      width: 100%;
      height: getRem(48);
    }

    &__btns {
      width: 100%;
      display: flex;
      flex-direction: column;

      & button + button {
        margin-top: getRem(10);
      }

      & .button.secondary {
        background: linear-gradient(
          275.53deg,
          #015681,
          #1d94d0 50.23%,
          #015681
        );

        &:hover {
          background: var(
            --Button-Gradient-2,
            linear-gradient(276deg, #004161 0%, #007ebd 50.23%, #004061 100%)
          );
        }
      }
    }
  }
}

.paywall-plans-free {
  &__card {
    width: getRem(340);
    max-width: 95%;
    padding: getRem(40);
    border-radius: getRem(20);
    border: getRem(1) solid var(--Card-Gradient, #04c);
    background: var(
      --Card-Stroke,
      linear-gradient(313deg, #039 0%, #101623 50.23%, #039 100%)
    );
    margin: getRem(16) auto getRem(32);

    @include breakpoint(tablet) {
      width: getRem(340);
      margin: getRem(26) auto getRem(32);
    }

    @include breakpoint(desktop) {
      margin: getRem(30) auto getRem(20);
      width: getRem(420);
    }

    & h3 {
      font-weight: 700;
      font-size: getRem(24);
      text-align: center;
      margin-bottom: getRem(20);

      @include breakpoint(desktop) {
        font-size: getRem(32);
      }

      & span {
        color: var(--Accent, #22b5ff);
      }
    }
  }

  &__title {
    font-size: getRem(26);
    text-align: center;
    margin: getRem(20) 0 0;
    font-weight: 700;

    @include breakpoint(tablet) {
      font-size: getRem(30);
    }

    @include breakpoint(desktop) {
      font-size: getRem(36);
    }
  }
}
