@import "../../../styles/funcs.scss";

.about {
  &_section {
    scroll-margin-top: getRem(25);
    background: var(--BG-Light-Blue, rgba(214, 241, 255, 0.9));
    padding: getRem(60) 0;

    @include breakpoint(tablet) {
      padding: getRem(80) 0;
    }

    @include breakpoint(desktop) {
      scroll-margin-top: getRem(-25);
      padding: getRem(150) 0;
    }
  }

  &_title {
    font-size: getRem(14);
    line-height: 1.2;
    text-align: center;
    color: var(--Stroke, #1481b8);
    font-weight: 700;

    @include breakpoint(tablet) {
      // font-size: getRem(20);
    }
    @include breakpoint(desktop) {
      font-size: getRem(20);
    }
  }

  &_subtitle {
    font-size: getRem(24);
    line-height: 1.2;
    text-align: center;
    color: var(--Almost-Black, #101010);
    font-weight: 600;

    @include breakpoint(tablet) {
      // font-size: getRem(44);
    }
    @include breakpoint(desktop) {
      font-size: getRem(44);
    }
  }

  &_benefits {
    &_list {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      opacity: 1;
      flex-direction: column;
      margin: getRem(30) auto 0;
      gap: getRem(20);
      width: 100%;
      max-width: getRem(320);

      @for $i from 1 through 10 {
        li:nth-child(#{$i}) {
          top: 50px * $i;
        }
      }

      @include breakpoint(tablet) {
        max-width: getRem(700);
        @for $i from 1 through 10 {
          li:nth-child(#{$i}) {
            top: 80px * $i;
          }
        }
      }

      @include breakpoint(desktop) {
        opacity: 0;
        flex-direction: row;
        margin: getRem(80) auto 0;
        max-width: getRem(1600);
        @for $i from 1 through 10 {
          li:nth-child(#{$i}) {
            top: 0;
          }
        }
      }
    }

    &_listItem {
      position: relative;
      padding: getRem(24);
      border-radius: getRem(20);
      opacity: 0;
      border: getRem(1) solid var(--Stroke, #1481b8);
      background: var(--White, #fff);

      width: 100%;
      // height: getRem(316);
      max-width: getRem(340);
      z-index: 1;

      @include breakpoint(tablet) {
        display: flex;
        gap: getRem(20);
        max-width: 100%;
      }
      @include breakpoint(desktop) {
        flex-direction: column;
        gap: 0;
        opacity: 1;
        max-width: 100%;
        width: getRem(340);
        height: getRem(316);
      }

      img {
        width: getRem(48);
        height: getRem(48);

        @include breakpoint(tablet) {
          width: getRem(64);
          height: getRem(64);
        }
      }

      .title {
        font-size: getRem(20);
        font-weight: 700;
        line-height: 1.2;
        text-align: left;
        max-width: getRem(200);
        position: absolute;
        top: getRem(22);
        left: getRem(88);
        color: var(--Stroke, #1481b8);

        @include breakpoint(tablet) {
          position: relative;
          font-size: getRem(20);
          top: 0;
          left: 0;
          max-width: 100%;
        }
        @include breakpoint(desktop) {
          font-size: getRem(24);
          margin-top: getRem(30);
        }

        span {
          font-size: inherit;
          font-weight: inherit;
        }
      }

      .text {
        font-size: getRem(14);
        font-weight: 400;
        line-height: 1.5;
        text-align: left;
        margin-top: getRem(20);
        color: var(--Almost-Black, #101010);

        @include breakpoint(tablet) {
          font-size: getRem(16);
          margin-top: getRem(12);
        }
      }
    }
  }
}
