@import "../../../styles/funcs.scss";

.button {
  position: relative;
  width: getRem(150);
  height: getRem(48);
  color: #ffffff;
  border-radius: getRem(8);
  // padding: getRem(10) getRem(30);
  border: none;
  font-size: getRem(16);
  transition: 400ms all;
  outline: none;
  cursor: pointer;
  overflow: hidden;
  z-index: 1;
  background: linear-gradient(
    275.53deg,
    #006395 0%,
    #22b5ff 50.23%,
    #006395 100%
  );
  font-weight: 600;

  &::before {
    content: "";
    position: absolute;
    width: calc(100% - getRem(4));
    height: calc(100% - getRem(4));
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgb(17, 26, 45);
    border-radius: getRem(8);
    z-index: -1;
  }

  // @include breakpoint(tablet) {
  //   width: getRem(200);
  // }

  &:hover {
    background: linear-gradient(
      275.53deg,
      #004161 0%,
      #007ebd 50.23%,
      #004061 100%
    );
  }

  &.secondary {
    background: linear-gradient(
      275.53deg,
      #006395 0%,
      #22b5ff 50.23%,
      #006395 100%
    );
    border: 1px solid rgba(20, 129, 184, 1);

    &:before {
      content: "";
      background: transparent;
    }

    &:hover {
      border: 1px solid var(--Stroke, #1481b8);
      background: var(
        --Button-Gradient-2,
        linear-gradient(276deg, #004161 0%, #007ebd 50.23%, #004061 100%)
      );
    }
  }

  &.full-width {
    width: 100%;
  }

  &.ext {
    min-width: getRem(200);
    padding: 0 getRem(20);
  }
}
