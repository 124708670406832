@import "../../../styles/funcs.scss";

.select-pay-popup {
  & h2 {
    font-size: getRem(20);
    font-weight: 600;

    @include breakpoint(tablet) {
      font-size: getRem(28);
    }
  }

  &__subtitle {
    text-decoration: underline;
    text-underline-offset: getRem(5);
    font-size: getRem(18);
    font-weight: 700;
    line-height: 1.4;
    margin-top: getRem(30);
    color: var(--Accent, #22b5ff);

    @include breakpoint(mobile_landscape) {
      & br {
        display: none;
      }
    }

    @include breakpoint(tablet) {
      font-size: getRem(21);
    }
  }

  &__box {
    margin: getRem(40) 0;
    display: flex;
    flex-direction: column;
    gap: getRem(10);
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: getRem(15);
    border-radius: getRem(10);
    background: var(--Almost-Black, #101010);
    cursor: pointer;

    &-img {
      //width: getRem(64);
      width: auto;
      height: getRem(24);

      @include breakpoint(tablet) {
        //width: getRem(80);
        width: auto;
        height: getRem(30);
      }
    }

    &-label {
      font-size: getRem(14);
      margin-left: getRem(10);
      margin-right: auto;

      @include breakpoint(tablet) {
        font-size: getRem(18);
        margin-left: getRem(15);
      }
    }
  }

  &__btm {
    border-top: getRem(2) solid var(--Stroke, #1481b8);
    padding: getRem(10) 0 0;

    &-lbl {
      font-size: getRem(10);
      line-height: 1.5;
      margin-top: getRem(15);

      &2 {
        font-size: getRem(14);
        line-height: 1.5;
        margin-top: getRem(15);
      }
    }

    &.discount {
      margin-bottom: getRem(20);

      & .select-pay-popup__btm-lbl {
        display: flex;
        justify-content: space-between;
        margin: 0;

        & p {
          font-weight: 600;

          &:first-child {
            font-size: getRem(16);
            color: var(--Light-Gray, var(--Greyscale-300, #b3b3b3));
          }

          &:last-child {
            font-size: getRem(20);
          }
        }
      }
    }
  }

  &__now {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & p {
      font-size: getRem(22);
      font-weight: 600;

      & span {
        color: var(--Accent, #22b5ff);
        font-size: getRem(28);
        font-weight: 700;
      }
    }
  }

  &__future {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: getRem(5) 0 getRem(20);

    & p {
      font-weight: 300;
      color: var(--Light-Gray, #b3b3b3);

      & span {
        font-size: getRem(18);
      }
    }
  }

  &__promo {
    padding-bottom: getRem(20);

    & p {
      font-size: getRem(14);
      font-weight: 600;
      margin-bottom: getRem(15);

      @include breakpoint(tablet) {
        font-size: getRem(16);
      }
    }

    &-apply {
      position: relative;
      margin-bottom: getRem(20);

      & .MuiTextField-root {
        margin: 0 !important;
      }

      & .MuiInputBase-input {
        padding-right: getRem(100) !important;
      }

      & button {
        width: getRem(75);
        height: getRem(36);
        position: absolute;
        right: getRem(10);
        top: 50%;
        transform: translate(0, -50%);
      }
    }
  }

  &__total-amount {
    
    & span {
      font-size: getRem(22) !important; 

    }
  }

  &.select-pay-popup_free-trial {
    & .select-pay-popup__box {
      margin: getRem(20) 0 getRem(40);
    }
  }
}
