@import "../../../../styles/funcs.scss";

.confirm-register {
  &__img {
    width: getRem(60);
    height: auto;
    margin: getRem(10) 0;
  }

  &__text {
    text-align: left;
    margin: 0;
    padding: getRem(25) 0 0;

    & ul {
      margin-top: getRem(5);
      padding: 0 0 0 getRem(25);
      list-style-type: disc;
    }

    & li {
      margin: 0;
      padding: 0;
    }
  }
}
