@import "../../../styles/funcs.scss";

#download {
  scroll-margin-top: getRem(50);

  @include breakpoint(desktop) {
    scroll-margin-top: getRem(125);
  }
}

.features {
  &_section {
    padding: getRem(60) 0 0;

    @include breakpoint(tablet) {
      padding: getRem(80) 0 0;
    }

    @include breakpoint(desktop) {
      padding: getRem(150) 0 0;
    }
  }

  &_card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    max-width: getRem(320);
    margin: 0 auto;

    @include breakpoint(tablet) {
      max-width: getRem(700);
      flex-wrap: wrap;
      height: getRem(400);
      align-items: start;
      justify-content: center;
    }
    @include breakpoint(desktop) {
      height: getRem(560);
      max-width: getRem(1200);
    }

    img {
      position: relative;
      width: getRem(320);
      height: getRem(400);
      opacity: 0;

      @include breakpoint(tablet) {
        left: -20%;
        width: getRem(320);
        height: getRem(400);
      }
      @include breakpoint(desktop) {
        width: getRem(520);
        height: getRem(560);
      }
    }

    .features {
      &_imgWrapper {
        order: 2;
        margin-top: getRem(30);

        @include breakpoint(tablet) {
          order: 1;
          margin-top: 0;
        }
        @include breakpoint(desktop) {
          order: 0;
        }
      }
      &_textBox {
        order: 1;

        @include breakpoint(tablet) {
          order: 2;
        }
        @include breakpoint(desktop) {
          order: 0;
          margin-left: getRem(110);
        }
      }
      &_title {
        font-size: getRem(14);
        text-align: center;
        font-weight: 500;
        line-height: 1.2;
        color: rgb(34, 181, 255);

        @include breakpoint(tablet) {
          font-size: getRem(16);
          text-align: left;
        }
        @include breakpoint(desktop) {
          font-size: getRem(20);
          text-align: left;
        }
      }
      &_subtitle {
        font-size: getRem(24);
        font-weight: 400;
        line-height: 1.2;
        text-align: center;

        @include breakpoint(tablet) {
          font-size: getRem(32);
          text-align: left;
        }
        @include breakpoint(desktop) {
          font-size: getRem(44);
          text-align: left;
        }
      }

      &_progressbars {
        order: 3;
        margin-top: getRem(30);
        width: getRem(320);
        display: flex;
        flex-direction: column;
        gap: getRem(44);

        @include breakpoint(tablet) {
          order: 2;
          width: getRem(320);
          margin-top: getRem(50);
        }
        @include breakpoint(desktop) {
          margin-top: getRem(80);
          margin-left: getRem(110);
          width: getRem(540);
          order: 0;
        }

        p {
          font-size: getRem(24);
          font-weight: 400;
          line-height: 1.2;
          text-align: left;
        }
      }
    }
    .bar {
      &_text {
        display: flex;
        justify-content: space-between;
        width: getRem(165);
      }
      &_block {
        width: 100%;
        height: getRem(6);
        background: #ffffff;
        opacity: 0.5;
        margin-top: getRem(12);
      }
      &_progress {
        display: block;
        width: getRem(135);
        height: getRem(6);
        background: linear-gradient(90deg, #31569e 0%, #2ebff0 100%);
      }
    }
  }
}

.home {
  &-download {
    height: 100%;
    padding-top: getRem(40);
    border-radius: getRem(20);
    border: getRem(1) solid var(--Card-Stroke, #039);
    background: var(--BG-2, #05090f);
    margin: getRem(80) auto 0;

    @include breakpoint(tablet) {
      padding: getRem(40);
      margin-top: getRem(80);
      max-width: 80vw;
    }

    @include breakpoint(desktop) {
      padding: getRem(40) 0;
      margin-top: getRem(150);
      width: getRem(1200);
    }

    & > h2 {
      font-size: getRem(14);
      text-align: center;
      font-weight: 700;
      padding: 0 5%;
      max-width: getRem(500);
      color: var(--Stroke, #1481b8);

      @include breakpoint(tablet) {
        font-size: getRem(16);
        margin: 0 auto;
        padding: 0;
      }

      @include breakpoint(desktop) {
        font-size: getRem(20);
      }
    }

    &__desc {
      font-size: getRem(24);
      font-weight: 500;
      text-align: center;
      margin-bottom: getRem(20);

      @include breakpoint(tablet) {
        font-size: getRem(32);
        margin-bottom: getRem(35);
      }

      @include breakpoint(desktop) {
        font-size: getRem(44);
      }
    }

    &__box {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: getRem(40);
      padding: getRem(30) getRem(40);
      width: fit-content;
      border-radius: getRem(10);
      background: var(
        --Figure-Gradient,
        linear-gradient(180deg, #0d1626 0%, #152036 100%)
      );
      margin: 0 getRem(20) getRem(20);

      @include breakpoint(tablet) {
        padding: getRem(20);
        margin: 0 auto;
      }

      @include breakpoint(tablet_landscape) {
        width: getRem(520);
        justify-content: center;
      }

      @include breakpoint(desktop) {
        flex-wrap: nowrap;
        padding: getRem(30) getRem(40);
        justify-content: flex-start;
        width: fit-content;
      }
    }

    &__item {
      & > p {
        text-align: center;
        font-size: getRem(18);
        margin-bottom: getRem(20);
      }

      & > img {
        width: getRem(200);
        height: auto;
        margin: getRem(20) auto 0;
        display: block;
        display: none;

        @include breakpoint(tablet) {
          display: block;
        }
      }
    }

    &__btn {
      border-radius: getRem(12);
      border: getRem(1.5) solid var(--Accent, #22b5ff);
      background: var(--Black, #000);
      display: flex;
      gap: getRem(16);
      padding: getRem(8) getRem(20);
      width: getRem(200);
      cursor: pointer;

      & img {
        width: getRem(26);
      }

      &-label {
        font-size: getRem(12);
        font-weight: 500;
        margin-bottom: getRem(-2.5);
      }

      &-store {
        font-size: getRem(18);
        font-weight: 600;
      }
    }
  }
}
