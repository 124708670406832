@import "../../../styles/funcs.scss";

#reviews {
  scroll-margin-top: getRem(185);

  @include breakpoint(tablet) {
    scroll-margin-top: getRem(225);
  }
}

.world {
  &_section {
    background: var(--BG-Light-Blue, rgba(214, 241, 255, 0.9));
    padding: getRem(60) 0;

    @include breakpoint(tablet) {
      padding: getRem(80) 0;
    }

    @include breakpoint(desktop) {
      padding: getRem(150) 0;
    }
  }

  &_title {
    font-size: getRem(14);
    line-height: 1.2;
    text-align: center;
    color: var(--Stroke, #1481b8);
    font-weight: 700;

    @include breakpoint(tablet) {
      font-size: getRem(20);
    }
  }
  &_subtitle {
    font-size: getRem(24);
    line-height: 1.21;
    text-align: center;
    color: var(--Almost-Black, #101010);
    font-weight: 600;

    @include breakpoint(tablet) {
      font-size: getRem(32);
    }

    @include breakpoint(desktop) {
      font-size: getRem(44);
    }
  }

  &_wrapper {
    position: relative;
    width: fit-content;
    margin: getRem(30) auto 0;

    @include breakpoint(tablet) {
      margin: getRem(60) auto 0;
    }
    @include breakpoint(desktop) {
      margin: getRem(60) auto 0;
    }
  }

  &_map {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    mask-image: url("../../../assets/map-mask.svg");
    mask-repeat: no-repeat;
    mask-size: auto 100%;
    mask-position: -100vw;
    -webkit-mask-image: url("../../../assets/map-mask.svg");
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: auto 100%;
    -webkit-mask-position: -100vw;
  }
}

.feedbacks {
  &_section {
    padding: getRem(60) 0 0;

    @include breakpoint(tablet) {
      padding: getRem(80) 0 getRem(30);
    }

    @include breakpoint(desktop) {
      padding: getRem(150) 0 getRem(100);
    }
  }
  &_title {
    font-size: getRem(24);
    font-weight: 400;
    line-height: 1.2;
    text-align: center;

    @include breakpoint(tablet) {
      font-size: getRem(32);
    }
    @include breakpoint(desktop) {
      font-size: getRem(44);
    }
  }
  &_slider {
    margin: getRem(40) auto 0;
    position: relative;
    max-width: getRem(1300);

    @include breakpoint(tablet) {
      padding-left: getRem(40);
      margin: getRem(60) auto 0;
    }
    @include breakpoint(desktop) {
    }
  }

  &_card {
    margin: 0 auto;
    width: getRem(300);
    height: getRem(340);
    min-height: getRem(200);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: getRem(24);
    border-radius: getRem(20);
    border: 1px solid rgba(20, 129, 184, 1);
    background: linear-gradient(180deg, #0d1626 0%, #152036 100%);

    @include breakpoint(tablet) {
      margin: 0 0 0 auto;
    }

    @include breakpoint(desktop) {
      margin: 0 auto;
      width: getRem(410);
      max-height: getRem(320);
      height: min-content;
    }

    img {
      width: getRem(80);
      height: getRem(80);
    }
    .name {
      font-size: getRem(24);
      font-weight: 400;
      line-height: 1.2;
    }
    .profession {
      font-size: getRem(18);
      font-weight: 400;
      line-height: 1.21;
      color: #878c96;
    }

    & .feedbacks_card-head {
      font-size: getRem(20);
      font-weight: 400;
      text-decoration: underline;
      text-underline-offset: getRem(5);
    }

    & .feedbacks_card-stars {
      display: flex;
      justify-items: center;
      margin: getRem(20) 0;
    }

    & .feedbacks_card-star {
      width: getRem(24);
      height: getRem(24);
      margin: 0 getRem(2);
    }

    .feedback {
      font-size: getRem(16);
      font-weight: 400;
      line-height: 1.5;
      text-align: left;
      margin-top: getRem(20);
    }

    &-img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 200%;
      border: 2px solid rgba(20, 129, 184, 1);
      background: rgb(17, 26, 45);
    }
  }
}

.arrowNext {
  position: absolute;
  top: 110%;
  right: 45%;
  width: getRem(45);
  height: getRem(45);
  border-radius: getRem(8);
  background: linear-gradient(
    275.53deg,
    #006395 0%,
    #22b5ff 50.23%,
    #006395 100%
  );
  z-index: 1;
  cursor: pointer;
  display: none !important;

  @include breakpoint(tablet) {
  }
  @include breakpoint(desktop) {
    display: block !important;
  }

  &:before {
    content: url("../../../assets/arrow.svg");
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: calc(100% - getRem(4));
    height: calc(100% - getRem(4));
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgb(16, 26, 45);
    border-radius: getRem(8);
    z-index: -1;
    font-size: getRem(15);
  }

  &:hover {
    background: linear-gradient(
      275.53deg,
      #004161 0%,
      #007ebd 50.23%,
      #004061 100%
    );
  }
}

.arrowPrev {
  @extend .arrowNext;
  top: 110%;
  left: 42%;

  &:before {
    transform: translate(-50%, -50%) rotate(180deg);
  }
}

.freeTrial {
  &_block {
    width: 100%;
    max-width: getRem(320);
    // height: getRem(368);
    padding: getRem(30);
    margin: getRem(60) auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: getRem(30);
    background: linear-gradient(
      130.69deg,
      rgba(0, 68, 204, 0.2) 0%,
      rgba(0, 34, 102, 0.2) 50.1%,
      rgba(0, 17, 51, 0.2) 100%
    );

    @include breakpoint(tablet) {
      margin: getRem(80) auto 0;
      padding: getRem(50);
      max-width: getRem(700);
    }
    @include breakpoint(desktop) {
      margin: getRem(230) auto 0;
      padding: getRem(80) getRem(100);
      max-width: getRem(1300);
    }
  }

  &_title {
    font-size: getRem(14);
    font-weight: 500;
    line-height: 1.2;
    color: rgba(34, 181, 255, 1);
    opacity: 0;

    @include breakpoint(tablet) {
      font-size: getRem(16);
    }
    @include breakpoint(desktop) {
      font-size: getRem(20);
    }
  }
  &_desc {
    font-size: getRem(24);
    font-weight: 400;
    line-height: 1.21;
    opacity: 0;
    text-align: center;

    @include breakpoint(tablet) {
      font-size: getRem(32);
    }
    @include breakpoint(desktop) {
      font-size: getRem(44);
    }
  }

  &_btn {
    margin-top: getRem(30);
    width: getRem(260);
    opacity: 0;

    @include breakpoint(tablet) {
      width: getRem(200);
    }
  }
}

.home-referral {
  padding: getRem(50) getRem(10) getRem(75);
  scroll-margin-top: getRem(30);

  @include breakpoint(tablet) {
    padding: getRem(50) getRem(50) getRem(75);
    scroll-margin-top: 0;
  }

  @include breakpoint(desktop) {
    padding: getRem(50) getRem(100) getRem(150);
    max-width: getRem(1300);
    margin: 0 auto;
  }
  & > h3 {
    font-size: getRem(14);
    font-weight: 500;
    line-height: 1.2;
    color: rgba(34, 181, 255, 1);
    text-align: center;

    @include breakpoint(tablet) {
      font-size: getRem(16);
    }
    @include breakpoint(desktop) {
      font-size: getRem(20);
    }
  }

  &__main-desc {
    font-size: getRem(24);
    font-weight: 400;
    line-height: 1.21;
    text-align: center;
    margin-bottom: getRem(40);

    @include breakpoint(tablet) {
      font-size: getRem(32);
    }
    @include breakpoint(desktop) {
      font-size: getRem(44);
    }
  }

  &__subtitle {
    font-size: getRem(18);
    text-align: center;
    font-weight: 600;
    margin: 0 0 getRem(20);

    & br {
      display: none;
    }

    @include breakpoint(tablet) {
      font-size: getRem(24);
    }

    @include breakpoint(desktop) {
      font-size: getRem(28);

      & br {
        display: block;
      }
    }
  }

  &__cards {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    //      flex-direction: column;
    justify-content: center;
    margin-bottom: getRem(40);
    gap: getRem(20);

    @include breakpoint(tablet) {
      flex-direction: column;
      align-items: center;
    }

    @include breakpoint(desktop) {
      flex-direction: row;
      gap: getRem(40);
    }
  }

  &__card {
    padding: getRem(20);
    border: getRem(1) solid var(--Stroke, #1481b8);
    border-radius: getRem(10);
    background: var(
      --Figure-Gradient,
      linear-gradient(180deg, #0d1626 0%, #152036 100%)
    );
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    @include breakpoint(tablet) {
      width: getRem(450);
    }

    @include breakpoint(desktop) {
      padding: getRem(30);
      flex-direction: column;
      justify-content: center;
      width: unset;
    }

    & p {
      font-size: getRem(14);
      text-align: left;
      margin-right: getRem(20);

      & br {
        display: none;
      }

      @include breakpoint(tablet) {
        font-size: getRem(18);
      }

      @include breakpoint(desktop) {
        font-size: getRem(20);
        margin-bottom: getRem(10);
        text-align: center;
        margin-right: 0;

        & br {
          display: block;
        }
      }
    }

    & h1 {
      font-size: getRem(22);
      font-weight: 700;
      color: #22b5ff;

      @include breakpoint(tablet) {
        font-size: getRem(28);
      }

      @include breakpoint(desktop) {
        font-size: getRem(32);
      }
    }
  }

  &__account {
    display: flex;
    flex-direction: column;
    align-items: center;

    & p {
      font-size: getRem(16);
      line-height: 1.2;
      text-align: center;
      margin-bottom: getRem(20);

      @include breakpoint(tablet) {
        font-size: getRem(18);
      }

      @include breakpoint(desktop) {
        font-size: getRem(20);
      }

      & br {
        display: none;

        @include breakpoint(desktop) {
          display: block;
        }
      }
    }
  }

  & .default-button {
    width: getRem(200);
    text-transform: none;
  }
}
