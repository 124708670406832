@import "../../styles/funcs.scss";

.profile {
  overflow: auto;
  max-width: 100vw;

  @include breakpoint(tablet) {
    min-height: unset;
    padding: 0 0 getRem(100);
  }

  &-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__header {
    padding: getRem(20) 0 getRem(30);
  }

  &__close-icon {
    width: getRem(24);
    height: auto;
    cursor: pointer;
    position: absolute;
    top: getRem(14);
    right: getRem(14);

    @include breakpoint(desktop) {
      position: static;
    }
  }

  &__box {
    margin: 0 auto;
    background: var(--BG-2, #05090f);
    max-width: 100vw;
    overflow: hidden;

    @include breakpoint(tablet) {
      max-width: 98vw;
      width: getRem(1516);
      border: getRem(1.5) solid var(--Stroke, #1481b8);
      display: flex;
      flex-wrap: wrap;
      border-radius: getRem(20);
    }

    @include breakpoint(desktop) {
      border: getRem(0.5) solid var(--Stroke, #1481b8);
    }
  }

  &__content {
    overflow-y: auto;

    @include breakpoint(tablet) {
      height: getRem(642);
      width: calc(100% - getRem(180));
    }

    @include breakpoint(desktop) {
      height: getRem(662);
    }
  }

  &__top {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: getRem(30) getRem(20);
    background: var(--BG, #101a2d);
    gap: getRem(20);
    position: relative;

    @include breakpoint(tablet) {
      flex-direction: row;
      margin: 0;
      width: 100%;
      border-radius: getRem(20) getRem(20) 0 0;
      border: getRem(1) solid var(--Stroke, #1481b8);
      flex-wrap: wrap;
      padding: getRem(40) getRem(20) getRem(30);
    }

    @include breakpoint(desktop) {
      padding: getRem(30) getRem(20);
    }

    & > h2 {
      color: var(--Accent, #22b5ff);
      font-size: getRem(24);
      font-weight: 500;
      margin: 0;
    }

    &-withdraw {
      display: flex;
      align-items: center;

      & button {
        height: getRem(36);
        width: getRem(100);
        margin-left: getRem(10);
      }
    }

    &-item {
      font-size: getRem(20);
      margin: getRem(8) 0;
      text-align: center;

      @include breakpoint(tablet) {
        text-align: left;
      }

      @include breakpoint(desktop) {
        margin: 0;
      }

      & span {
        color: var(--Accent, #22b5ff);
        font-weight: 500;
      }
    }
  }

  &__logout {
    color: var(--Red, #da1414);
    font-size: getRem(20);
    cursor: pointer;
    display: flex;
    align-items: center;

    & img {
      width: getRem(20);
      height: auto;
      margin-left: getRem(10);
    }
  }

  &__tabs {
    display: none;
    overflow-x: auto;

    @include breakpoint(tablet) {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: getRem(180);
      background: var(--BG, #101a2d);
    }
  }

  &__tabs-m {
    display: block;

    @include breakpoint(tablet) {
      display: none;
    }

    &-popup {
      background: var(
        --Card-Gradient,
        linear-gradient(
          131deg,
          rgba(0, 68, 204, 0.2) 0%,
          rgba(0, 34, 102, 0.2) 50.1%,
          rgba(0, 17, 51, 0.2) 100%
        )
      );
      background-color: var(--BG, #101a2d);
      width: getRem(200);
    }

    &-btn {
      width: 100%;
      border: none;
      font-size: getRem(20);
      background: var(
        --Card-Gradient,
        linear-gradient(
          131deg,
          rgba(0, 68, 204, 0.2) 0%,
          rgba(0, 34, 102, 0.2) 50.1%,
          rgba(0, 17, 51, 0.2) 100%
        )
      );
      color: #fff;
      cursor: pointer;
      padding: getRem(20);

      & > img {
        width: getRem(10);
        height: auto;
        margin-left: getRem(10);
        transition: 0.7s all;

        &.active {
          transform: rotate(180deg);
        }
      }
    }

    &-item {
      font-size: getRem(20);
      text-align: center;
      padding: getRem(15);
      cursor: pointer;
    }
  }

  &__tab {
    display: flex;
    justify-content: center;
    align-items: center;
    height: getRem(76);
    font-size: getRem(18);
    cursor: pointer;
    border-bottom: getRem(4) solid transparent;
    transition: 400ms all;
    height: getRem(60);

    &.active {
      background-color: var(--Stroke, #1481b8);
    }
  }
}

.profile {
  &-download {
    height: 100%;
    overflow-y: auto;
    padding-top: getRem(40);

    @include breakpoint(tablet) {
      padding: getRem(40);
    }

    @include breakpoint(desktop) {
      padding: getRem(40) 0 0;
    }

    & > h2 {
      font-size: getRem(18);
      text-align: center;
      font-weight: 600;
      padding: 0 5%;
      max-width: getRem(500);
      margin: 0 auto getRem(40);

      @include breakpoint(tablet) {
        font-size: getRem(20);
        margin-bottom: getRem(20);
        padding: 0;
      }

      @include breakpoint(desktop) {
        margin-bottom: getRem(40);
      }
    }

    &__box {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: getRem(40);
      padding: getRem(30) getRem(40);
      width: fit-content;
      border-radius: getRem(10);
      background: var(
        --Figure-Gradient,
        linear-gradient(180deg, #0d1626 0%, #152036 100%)
      );
      margin: 0 getRem(20) getRem(120);

      @include breakpoint(tablet) {
        padding: getRem(20);
        margin: 0 auto getRem(20);
      }

      @include breakpoint(tablet_landscape) {
        width: getRem(520);
        justify-content: center;
        margin: 0 auto getRem(120);
      }

      @include breakpoint(desktop) {
        flex-wrap: nowrap;
        padding: getRem(30) getRem(40);
        justify-content: flex-start;
        width: fit-content;
      }
    }

    &__item {
      & > p {
        text-align: center;
        font-size: getRem(18);
        margin-bottom: getRem(20);
      }

      & > img {
        width: getRem(200);
        height: auto;
        margin: getRem(20) auto 0;
        display: block;
        display: none;

        @include breakpoint(tablet) {
          display: block;
        }
      }
    }

    &__btn {
      border-radius: getRem(12);
      border: getRem(1.5) solid var(--Accent, #22b5ff);
      background: var(--Black, #000);
      display: flex;
      gap: getRem(16);
      padding: getRem(8) getRem(20);
      width: getRem(200);
      cursor: pointer;

      & img {
        width: getRem(26);
      }

      &-label {
        font-size: getRem(12);
        font-weight: 500;
        margin-bottom: getRem(-2.5);
      }

      &-store {
        font-size: getRem(18);
        font-weight: 600;
      }
    }

    //    & .profile-download__box {
    //      background: #000;
    //      border-radius: getRem(20);
    //      padding: getRem(40) getRem(20);
    //    }

    &__desc {
      width: getRem(480);
      max-width: 100%;
      font-size: getRem(20);
      text-align: center;
      margin: 0 auto;
      font-size: getRem(14);

      @include breakpoint(desktop) {
        font-size: getRem(20);
      }
    }

    &__tape {
      transform: translate(-50%, calc(-50% + getRem(-13))) rotate(-12deg);
    }
  }

  &-plans {
    padding: getRem(40) 0 getRem(120);

    @include breakpoint(tablet) {
      padding: getRem(40) 0;
    }
  }

  &-billing {
    padding: getRem(40) getRem(20) getRem(120);
    position: relative;
    height: 100%;

    @include breakpoint(tablet) {
      padding: getRem(40);
      overflow-y: auto;
    }

    @include breakpoint(desktop) {
      padding: getRem(40) getRem(50);
    }

    & > h2 {
      font-size: getRem(24);
      text-align: center;
      font-weight: 600;
      margin-bottom: getRem(20);

      @include breakpoint(desktop) {
        font-size: getRem(28);
      }
    }

    &__box {
      border-radius: getRem(10);
      border: getRem(1) solid var(--Stroke, #1481b8);
      min-height: getRem(100);
      overflow: hidden;
      overflow-x: auto;

      & table {
        width: 100%;
        border-collapse: collapse;

        & thead tr {
          border-bottom: getRem(1) solid var(--Stroke, #1481b8);
          background: var(
            --Figure-Gradient,
            linear-gradient(180deg, #0d1626 0%, #152036 100%)
          );

          & th {
            font-weight: 600;
            width: 33%;
            font-size: getRem(16);

            @include breakpoint(tablet) {
              font-size: getRem(18);
            }
          }
        }

        & tr {
          height: getRem(62);

          &:not(:last-child) {
            border-bottom: getRem(1) solid var(--Stroke, #1481b8);
          }

          & td {
            padding: getRem(20);
            text-align: center;
            font-size: getRem(14);
            white-space: nowrap;

            @include breakpoint(tablet) {
              font-size: getRem(18);
            }
          }
        }
      }
    }

    &__empty {
      position: absolute;
      left: 50%;
      top: 45%;
      transform: translate(-50%, -50%);
      font-size: getRem(16);
      width: 100%;
      text-align: center;
    }
  }

  &-subscriptions {
    padding: getRem(40) 0 getRem(120);
    position: relative;
    height: 100%;

    @include breakpoint(tablet) {
      padding: getRem(40) 0 0;
    }

    & > h2 {
      font-size: getRem(24);
      text-align: center;
      font-weight: 600;

      @include breakpoint(desktop) {
        font-size: getRem(28);
      }
    }

    &__box {
      padding: getRem(20);
      min-height: getRem(200);
      height: 100%;

      @include breakpoint(tablet) {
        padding: getRem(20) getRem(40);
      }

      @include breakpoint(desktop) {
        padding: getRem(20) getRem(50);
      }
    }

    &__item {
      border: 1px solid var(--Stroke, #1481b8);
      border-radius: getRem(10);
      padding: getRem(20);
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;

      @include breakpoint(desktop) {
        flex-direction: row;
        align-items: flex-end;
      }

      &:not(:last-child) {
        margin-bottom: getRem(50);
      }

      &-inner {
        width: 100%;
        margin: getRem(10) 0;

        @include breakpoint(desktop) {
          width: auto;
          margin: 0;
        }
      }

      & > h3 {
        width: 100%;
        color: var(--Accent, #22b5ff);
        font-weight: 500;
        font-size: getRem(20);
        margin: 0;
        text-align: center;

        @include breakpoint(desktop) {
          font-size: getRem(24);
        }
      }

      & p {
        margin: getRem(10) 0 0;

        & span {
          color: var(--Accent, #22b5ff);
        }
      }

      &.inactive {
        & h3,
        p {
          color: #a1b2c7;
        }
      }
    }

    &__btn {
      font-size: getRem(16);
      font-weight: 500;
      color: var(--White, #fff);
      padding: getRem(10) getRem(30);
      border-radius: getRem(12);
      background: var(--Red, #da1414);
      border: none;
      cursor: pointer;
      margin: 0 auto;
      margin-top: getRem(20);

      @include breakpoint(tablet) {
        margin: 0;
      }

      &:hover {
        color: #fff;
        background: rgb(229, 49, 37, 0.9);
      }
    }

    &__empty {
      position: absolute;
      left: 50%;
      top: 45%;
      transform: translate(-50%, -50%);
      font-size: getRem(16);
      width: 100%;
      text-align: center;
    }
  }

  &-reports {
    &__box {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: getRem(60) 12%;
    }

    &__item {
      display: flex;
      justify-content: flex-start;
      padding: getRem(20) 0 getRem(50);
      border-top: getRem(1) solid #0b2e88;
    }

    &__date {
      margin: 0;
      width: getRem(360);
    }

    &__desc {
      font-size: getRem(18);
      margin: 0;
    }
  }

  &-referral {
    padding: getRem(40) 0;

    @include breakpoint(tablet) {
      padding: getRem(30);
    }

    @include breakpoint(desktop) {
      padding: getRem(50);
    }

    & > h2,
    .profile-referral__box > h2 {
      text-align: center;
      font-weight: 600;
      padding: 0 getRem(20);

      @include breakpoint(tablet) {
        font-size: getRem(24);
        padding: 0;
      }

      @include breakpoint(desktop) {
        font-size: getRem(28);
      }
    }

    &__box {
      padding: getRem(30) getRem(10);
      font-size: getRem(16);

      @include breakpoint(tablet) {
        padding: getRem(30);
        font-size: getRem(18);
      }

      @include breakpoint(desktop) {
        padding: getRem(50);
        font-size: getRem(20);
      }
    }

    &__cards {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      //      flex-direction: column;
      justify-content: center;
      margin-bottom: getRem(40);
      gap: getRem(20);

      @include breakpoint(tablet) {
        flex-direction: column;
      }

      @include breakpoint(desktop) {
        flex-direction: row;
        gap: getRem(40);
      }
    }

    &__card {
      padding: getRem(20);
      border: getRem(1) solid var(--Stroke, #1481b8);
      border-radius: getRem(10);
      background: var(
        --Figure-Gradient,
        linear-gradient(180deg, #0d1626 0%, #152036 100%)
      );
      flex: 1;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;

      @include breakpoint(desktop) {
        padding: getRem(30);
        flex-direction: column;
        justify-content: center;
      }

      & p {
        font-size: getRem(14);
        text-align: left;
        margin-right: getRem(20);

        & br {
          display: none;
        }

        @include breakpoint(tablet) {
          font-size: getRem(18);
        }

        @include breakpoint(desktop) {
          font-size: getRem(20);
          margin-bottom: getRem(10);
          text-align: center;
          margin-right: 0;

          & br {
            display: block;
          }
        }
      }

      & h1 {
        font-size: getRem(22);
        font-weight: 700;
        color: #22b5ff;

        @include breakpoint(tablet) {
          font-size: getRem(28);
        }

        @include breakpoint(desktop) {
          font-size: getRem(32);
        }
      }
    }

    &__item {
      padding: getRem(30);
      border: getRem(1) solid var(--Stroke, #1481b8);
      border-radius: getRem(10);

      & + .profile-referral__item {
        margin-top: getRem(40);
      }

      & h3 {
        width: 100%;
        font-weight: 600;
        font-size: getRem(24);
        margin: 0 0 getRem(10);
      }

      & p,
      h3 {
        @include breakpoint(desktop) {
          text-align: center;
        }
      }

      & span {
        color: #22b5ff;
        font-weight: 600;
        font-size: getRem(20);

        @include breakpoint(desktop) {
          font-size: getRem(24);
        }
      }
    }

    &__btn {
      padding: 0 getRem(15);
      height: getRem(30);
      width: unset;
      margin: getRem(10) 0;
      border-radius: getRem(10);

      @include breakpoint(desktop) {
        height: getRem(48);
        padding: 0 getRem(30);
        margin: 0;
        margin-left: getRem(30);
      }
    }

    &__link {
      display: block;

      @include breakpoint(desktop) {
        display: inline;
      }
    }

    &__stats {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      gap: getRem(10);

      @include breakpoint(desktop) {
        flex-direction: row;
        gap: 0;
      }

      & p {
        & span {
          display: block;

          @include breakpoint(tablet) {
            display: inline;
          }
        }
      }
    }

    &__banners {
      font-size: getRem(18);
      font-weight: 600;
      text-align: center;
      margin: getRem(40) 0;

      @include breakpoint(tablet) {
        font-size: getRem(20);
      }

      @include breakpoint(desktop) {
        font-size: getRem(24);

        & br {
          display: none;
        }
      }

      & a {
        color: #22b5ff;
        text-decoration: underline;
        text-underline-offset: getRem(3);
      }
    }

    &__table {
      border-radius: getRem(10);
      border: getRem(1) solid var(--Stroke, #1481b8);
      min-height: getRem(100);
      overflow: hidden;
      overflow-x: auto;

      & table {
        width: 100%;
        border-collapse: collapse;

        & thead tr {
          border-bottom: getRem(1) solid var(--Stroke, #1481b8);
          background: var(
            --Figure-Gradient,
            linear-gradient(180deg, #0d1626 0%, #152036 100%)
          );

          & th {
            font-weight: 600;
            width: 25%;
            font-size: getRem(16);

            @include breakpoint(tablet) {
              font-size: getRem(18);
            }
          }
        }

        & tr {
          height: getRem(62);

          &:not(:last-child) {
            border-bottom: getRem(1) solid var(--Stroke, #1481b8);
          }

          & td {
            padding: getRem(20);
            text-align: center;
            font-size: getRem(14);
            white-space: nowrap;

            @include breakpoint(tablet) {
              font-size: getRem(18);
            }
          }
        }
      }

      &-lbl {
        font-size: getRem(18);
        font-weight: 600;
        text-align: center;
        margin: getRem(40) 0 getRem(10);

        @include breakpoint(tablet) {
          font-size: getRem(20);
        }

        @include breakpoint(desktop) {
          font-size: getRem(24);
        }
      }
    }
  }

  &-settings {
    height: 100%;

    &__box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: getRem(40) 0 getRem(120);
    }

    &__email {
      font-size: getRem(20);
      text-align: center;
      margin: 0 0 getRem(40);
      padding: 0 getRem(15);

      @include breakpoint(tablet) {
        padding: 0;
      }

      & span {
        color: var(--Accent, #22b5ff);
      }
    }
  }
}
