@import "../../../styles/funcs.scss";

:root {
  --progress: 0;
}

.weOffer {
  &_section {
    padding: getRem(60) 0;

    @include breakpoint(tablet) {
      padding: getRem(80) 0;
    }

    @include breakpoint(desktop) {
      padding: getRem(150) 0;
    }
  }

  &_card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: getRem(1200);
    flex-direction: column;
    margin: 0 auto;

    @include breakpoint(tablet) {
      max-width: getRem(700);
      flex-direction: row;
    }
    @include breakpoint(desktop) {
      max-width: getRem(1200);
    }
  }

  &_textContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: getRem(40);
    width: getRem(270);

    @include breakpoint(tablet) {
      align-items: start;
      gap: getRem(50);
      width: getRem(320);
    }
    @include breakpoint(desktop) {
      width: getRem(540);
    }
  }

  &_progressBlock {
    display: flex;
    // align-items: end;
    align-items: center;
    opacity: 0;
    width: getRem(271);

    @include breakpoint(tablet) {
    }
    @include breakpoint(desktop) {
      align-items: end;
      width: 100%;
    }

    .circular-progress {
      width: getRem(100);
      height: getRem(100);
      margin-right: getRem(30);
      transform: rotate(90deg);
      --size: 100px;
      --half-size: calc(var(--size) / 2);
      --stroke-width: 8px;
      --radius: calc((var(--size) - var(--stroke-width)) / 2);
      --circumference: calc(var(--radius) * pi * 2);
      --dash: calc((var(--progress) * var(--circumference)) / 100);
      // animation: progress-animation 1s linear 0s 1 forwards;
      @include breakpoint(tablet) {
        --size: 100px;
        width: getRem(100);
        height: getRem(100);
      }
      @include breakpoint(desktop) {
        --size: 160px;
        min-width: getRem(160);
        min-height: getRem(160);
      }
    }

    .circular-progress circle {
      cx: calc(var(--size) / 2);
      cy: calc(var(--size) / 2);
      r: var(--radius);
      stroke-width: var(--stroke-width);
      fill: none;
      stroke-linecap: round;
    }

    .circular-progress circle.fg {
      transform: rotate(-90deg);
      transform-origin: var(--half-size) var(--half-size);
      stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
      transition: stroke-dasharray 0.3s linear 0s;
      stroke: #5394fd;
    }

    .digit {
      font-size: getRem(50);
      font-weight: 300;
      line-height: 1.22;
      text-align: left;

      @include breakpoint(tablet) {
        // font-size: getRem(50);
      }
      @include breakpoint(desktop) {
        font-size: getRem(80);
      }
    }

    .desc {
      font-size: getRem(20);
      font-weight: 400;
      line-height: 1.2;
      text-align: left;
      width: getRem(141);

      @include breakpoint(tablet) {
        // font-size: getRem(24);
        margin-top: getRem(10);
      }
      @include breakpoint(desktop) {
        width: getRem(320);
        font-size: getRem(24);
      }
    }
  }

  &_trialBtn {
    opacity: 0;
    width: getRem(200);
  }

  &_title {
    font-size: getRem(14);
    font-weight: 500;
    line-height: 1.2;
    text-align: center;
    color: rgb(34, 181, 255);

    @include breakpoint(tablet) {
      font-size: getRem(16);
      text-align: left;
    }
    @include breakpoint(desktop) {
      font-size: getRem(20);
      text-align: left;
    }
  }
  &_subtitle {
    font-size: getRem(24);
    font-weight: 400;
    line-height: 1.2;
    text-align: center;

    @include breakpoint(tablet) {
      font-size: getRem(32);
      text-align: left;
    }
    @include breakpoint(desktop) {
      font-size: getRem(44);
      text-align: left;
    }
  }

  &_imageWrapper {
    position: relative;
    opacity: 0;
    margin-top: getRem(40);
    background: url(../../../assets/mobile2.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    border-radius: getRem(30);

    width: getRem(320);
    height: getRem(360);

    @include breakpoint(tablet) {
      margin-top: 0;
    }
    @include breakpoint(desktop) {
      width: getRem(560);
      height: getRem(520);
    }

    .animatedContent {
      &_list {
        position: absolute;
        display: flex;
        flex-direction: row;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        flex-wrap: wrap;
        opacity: 0;
        height: getRem(140);
        width: getRem(320);
        // height: getRem(180);
        // width: getRem(360);

        @include breakpoint(tablet) {
          height: getRem(140);
          width: getRem(320);
        }
        @include breakpoint(desktop) {
          height: getRem(180);
          width: getRem(360);
        }
      }
      &_listItem {
        position: absolute;
        display: flex;
        align-items: center;
        text-align: center;
        width: getRem(160);
        height: getRem(70);
        padding: getRem(16) getRem(20);
        background: linear-gradient(
          180deg,
          rgba(13, 22, 38, 0.8) 0%,
          rgba(21, 32, 54, 0.8) 100%
        );
        border-radius: getRem(10);
        border: 1px solid rgba(138, 216, 255, 1);
        font-weight: 600;
        line-height: 1.2;
        font-size: getRem(16);

        @include breakpoint(tablet) {
          // width: getRem(180);
          // height: getRem(90);
          // padding: getRem(20) getRem(10);
          // font-size: getRem(20);
        }
        @include breakpoint(desktop) {
          width: getRem(180);
          height: getRem(90);
          padding: getRem(20) getRem(10);
          font-size: getRem(20);
        }

        &:nth-child(1) {
          position: absolute;
          top: 0;
          left: 0;
        }
        &:nth-child(2) {
          position: absolute;
          top: 0;
          right: 0;
        }
        &:nth-child(3) {
          position: absolute;
          bottom: 0;
          left: 0;
        }
        &:nth-child(4) {
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
    }
  }
}
