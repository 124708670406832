@import "../../../styles/funcs.scss";

.paywall-footer {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-around;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(getRem(10));
  text-align: center;
  padding: getRem(20) 0 getRem(60);

  @include breakpoint(tablet_landscape) {
    flex-direction: row;
    padding: getRem(20) getRem(100) getRem(60);
  }

  &__support {
    margin: getRem(30) auto;

    @include breakpoint(tablet_landscape) {
      margin: 0 auto;
    }

    & a {
      color: var(--Accent, #22b5ff);
    }
  }

  &__privacy-btn {
    margin-bottom: getRem(20);
    cursor: pointer;

    @include breakpoint(tablet_landscape) {
      margin: 0 0 0 getRem(20);
    }

    a {
      text-decoration: none;
      color: white;

      &:hover {
        color: var(--Accent, #22b5ff);
      }
    }

    &:nth-child(2) {
      @include breakpoint(tablet_landscape) {
        margin: 0 getRem(20) 0 auto;
      }
    }
  }

  &__privacy-rights {
    margin: getRem(20) 0;

    @include breakpoint(mobile_landscape) {
      margin: getRem(30) 0 getRem(20);
    }

    @include breakpoint(tablet_landscape) {
      margin: 0;
    }
  }
}
