@import "../../../styles/funcs.scss";

.connect-wallet__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
}

.connect-wallet__wallet-img {
  width: getRem(35);
  height: getRem(35);
  margin-bottom: getRem(5);

  @include breakpoint(tablet) {
    width: getRem(50);
    height: getRem(50);
  }

  & img {
    width: 100%;
    height: 100%;
  }
}

.connect-wallet__wallets {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: getRem(10);
  padding: 0;

  @include breakpoint(tablet) {
    gap: getRem(20);
  }

  &-item {
    width: getRem(70);

    @include breakpoint(tablet) {
      width: getRem(140);
    }

    & button {
      background: none;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border: none;
      padding: getRem(10) 0;
      transition: 400ms all;

      &:hover {
        border-radius: getRem(10);
        background: rgb(0, 0, 0, 0.1);
      }

      & span {
        font-size: getRem(16);
        color: #fff;
        display: block;
        text-transform: capitalize;
        margin-top: getRem(10);

        @include breakpoint(tablet) {
          font-size: getRem(18);
        }
      }
    }
  }
}

.connect-wallet__no-wallets {
  font-size: getRem(16);
  font-weight: 400;

  @include breakpoint(tablet) {
    font-size: getRem(20);
  }
}
