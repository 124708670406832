@import "../../../styles/funcs.scss";

.radiobutton {
  display: block;
  position: relative;
  width: getRem(24);
  height: getRem(24);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    border: getRem(0) solid var(--Almost-Black, #101010);
  }

  &__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: getRem(24);
    width: getRem(24);
    border-radius: 50%;
    transition: 0.35s all;
    border: getRem(3) solid var(--Accent, #22b5ff);

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: getRem(10);
      height: getRem(10);
      border-radius: 50%;
      background-color: transparent;
      transition: 0.35s all;
    }
  }

  & input:checked ~ .radiobutton__checkmark::after {
    background-color: var(--Accent, #22b5ff);
  }
}
