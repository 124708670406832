@import "../../styles/funcs.scss";

.referral-banners {
  padding: getRem(60) getRem(20) getRem(50);

  @include breakpoint(tablet) {
    padding: getRem(75) getRem(50) getRem(100);
  }

  @include breakpoint(desktop) {
    padding: getRem(75) getRem(300) getRem(150);
  }

  & a {
    text-decoration: underline;
    text-underline-offset: getRem(3);
  }

  &__group {
    margin-top: getRem(40);

    @include breakpoint(tablet) {
      margin-top: getRem(50);
    }

    @include breakpoint(desktop) {
      margin-top: getRem(100);
    }
  }

  &__group-lbl {
    font-size: getRem(20);
    font-weight: 500;
    margin-bottom: getRem(20);
    text-align: center;

    @include breakpoint(tablet) {
      font-size: getRem(24);
    }

    @include breakpoint(desktop) {
      text-align: left;
      font-size: getRem(30);
    }
  }

  &__box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: getRem(20);

    @include breakpoint(tablet) {
      gap: getRem(40);
    }

    @include breakpoint(desktop) {
      justify-content: flex-start;
    }

    &.w-300 .referral-banners__img-wrapper {
      width: getRem(125);
      height: getRem(125);

      @include breakpoint(tablet) {
        width: getRem(150);
        height: getRem(150);
      }

      @include breakpoint(desktop) {
        width: getRem(300);
        height: getRem(300);
      }
    }
    &.w-414 .referral-banners__img-wrapper {
      width: getRem(212);
      height: getRem(103.5);

      @include breakpoint(desktop) {
        width: getRem(414);
        height: getRem(207);
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;

    & img {
      width: 100%;
      height: 100%;
    }

    & p {
      font-size: getRem(12);
      text-align: center;
      text-decoration: underline;
      text-underline-offset: getRem(3);
      margin-top: getRem(10);

      @include breakpoint(tablet) {
        font-size: getRem(14);
      }

      @include breakpoint(desktop) {
        font-size: getRem(20);
      }
    }
  }
}
