@import "../../../styles/funcs.scss";

.burgerBtn {
  height: getRem(48);
  width: getRem(48);
  cursor: pointer;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  padding: getRem(13) getRem(10);
  z-index: 1100;

  @include breakpoint(desktop) {
    display: none;
  }
}

.burgerBtn span,
.burgerBtn span::before,
.burgerBtn span::after {
  background: rgba(34, 181, 255, 1);

  border-radius: getRem(4);
  content: "";
  position: absolute;
  width: getRem(28);
  height: getRem(2);
  margin-top: getRem(10);

  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.burgerBtn span::before {
  margin-top: getRem(-10);
}

.burgerBtn span::after {
  margin-top: getRem(10);
}

.burgerBtn.active span {
  background: transparent;
}

.burgerBtn.active span::before {
  margin-top: 0;

  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.burgerBtn.active span::after {
  margin-top: 0;

  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
