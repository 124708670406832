@import "../../styles/funcs.scss";

.paywall {
  &-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    & .paywall-footer {
      margin-top: auto;
    }
  }

  &-logo {
    width: getRem(169);
    padding-top: getRem(20);
    cursor: pointer;

    & img {
      width: 100%;
    }
  }
}
