@import "../styles/funcs.scss";

.pointer {
  cursor: pointer;
}

.default-button {
  position: relative;
  width: getRem(150);
  height: getRem(48);
  color: #ffffff;
  border-radius: getRem(8);
  // padding: getRem(10) getRem(30);
  border: none;
  font-size: getRem(16);
  transition: 400ms all;
  outline: none;
  cursor: pointer;
  overflow: hidden;
  z-index: 1;
  text-transform: capitalize;
  background: linear-gradient(
    275.53deg,
    #006395 0%,
    #22b5ff 50.23%,
    #006395 100%
  );
  font-weight: 600;

  &::before {
    content: "";
    position: absolute;
    width: calc(100% - getRem(4));
    height: calc(100% - getRem(4));
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgb(17, 26, 45);
    border-radius: getRem(8);
    z-index: -1;
  }

  // @include breakpoint(tablet) {
  //   width: getRem(200);
  // }

  &:hover {
    background: linear-gradient(
      275.53deg,
      #004161 0%,
      #007ebd 50.23%,
      #004061 100%
    );
  }
}

.secondary-button {
  @extend .default-button;

  background: linear-gradient(
    275.53deg,
    #006395 0%,
    #22b5ff 50.23%,
    #006395 100%
  );
  border: 1px solid rgba(20, 129, 184, 1);

  &:before {
    content: "";
    background: transparent;
  }
}

.close-popup-icon {
  position: absolute;
  right: getRem(12.69);
  top: getRem(12.69);
  cursor: pointer;
  z-index: 1000;

  img {
    width: getRem(24);
    height: getRem(24);
  }
}

.popup-content {
  width: auto !important;
}

.lh-150 {
  line-height: 1.5;
}

.extra-bold {
  font-weight: 700;
}

.t-14 {
  font-size: getRem(14);
}

.t-18 {
  font-size: getRem(18);
}

.mb-40 {
  margin-bottom: getRem(40);
}

.mt-10 {
  margin-top: getRem(10);
}

.mt-20 {
  margin-top: getRem(20);
}

.mb-5 {
  margin-bottom: getRem(5);
}

.mb-10 {
  margin-bottom: getRem(10);
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gap-10 {
  gap: getRem(10);
}

.text-field {
  margin-bottom: getRem(20) !important;
  width: 100%;

  & .MuiInputBase-root {
    color: white !important;
  }

  & .MuiOutlinedInput-root {
    background: var(--BG, #101a2d) !important;

    & .MuiOutlinedInput-notchedOutline {
      border-color: #e7e7e7 !important;
      border-color: #1481b8 !important;
      border-radius: getRem(8) !important;

      &:hover {
        border-color: #e16962 !important;
      }
    }
  }

  & .MuiInputLabel-root {
    font-size: getRem(16) !important;
    line-height: getRem(23) !important;
    color: #a1b2c7 !important;
  }
}

.link {
  transition: 0.7s all;
  color: var(--Accent, #22b5ff);
  text-decoration-line: underline;
}

.without-overflow {
  overflow: unset !important;
}